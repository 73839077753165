<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import Swal from "sweetalert2";

import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/msoeawqm.json";
import axios from "axios";
import animationData1 from "@/components/widgets/gsqxdxog.json";

export default {
  page: {
    title: "帳號管理",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
    Multiselect,
  },
  data() {
    return {
      title: "帳號管理",
      items: [
        {
          text: "權限管理",
          href: "/",
        },
        {
          text: "帳號管理",
          active: true,
        },
      ],
      sortby: null,

      // 資料
      data: [],
      page: 1,
      perPage: 10,
      pages: [],
      searchQuery: null,

      temp_data: {},

      defaultOptions: {
        animationData: animationData,
      },
      defaultOptions1: {
        animationData: animationData1,
      },

      // loading
      loading: false,

      // error handle
      submitted: false,
      result_msg_variant: "danger",
      result_msg: null,
      form_errors: {},

      // auth_selected
      auth_options: [],
    };
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.data);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return (
            data.account.toLowerCase().includes(search) ||
            data.email.toLowerCase().includes(search) ||
            data.role.toLowerCase().includes(search) ||
            data.updated_at.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
  watch: {
    data() {
      this.setPages();
    },
    sortby() {
      if (this.sortby) {
        this.sortArraysByKey(this.data, this.sortby);
      } else {
        this.sortArraysByKey(this.data, "id");
      }
    },
  },
  created() {
    this.setPages();
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    sortArraysByKey(arrays, key) {
      arrays.sort((a, b) => (a[key] > b[key] ? 1 : -1));
    },
    editModal(itemData) {
      document.getElementById("editModalLabel").innerHTML = "修改帳號";
      document.getElementsByClassName("passwordLabel")[0].innerHTML = "新密碼";

      // let result = this.data.findIndex((o) => o.id == itemData.id);
      this.temp_data.id = itemData.id;
      this.temp_data.account = itemData.account;
      this.temp_data.email = itemData.email;
      this.temp_data.role = itemData.role;

      let auth_array = itemData.auths.map((i) => i.id);
      this.temp_data.auth_selected = auth_array;

      document.getElementById("edit-btn").style.display = "block";
      document.getElementById("add-btn").style.display = "none";
      document.getElementById("old_password_block").style.display = "block";
    },
    async updateData() {
      this.submitted = true;
      this.loading = true;
      await axios
        .post(
          `${process.env.VUE_APP_BACKEND_URL}user/${this.temp_data.id}`,
          this.temp_data
        )
        .then((result) => {
          if (result.data.status == 0) {
            this.closeModal();
            return (this.result_msg = result.data.msg);
          }
          let i = this.data.findIndex((o) => o.id == this.temp_data.id);
          this.data[i] = result.data.data;
          this.temp_data = {};
          this.result_msg = result.data.msg;
          this.result_msg_variant = "success";
          this.submitted = false;
          this.closeModal();
        })
        .catch((error) => {
          this.result_msg_variant = "danger";
          this.result_msg = error.response.data.msg;
          this.form_errors = error.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async deletedata(itemData) {
      Swal.fire({
        title: "確認刪除?",
        text: "刪除後無法再復原資料!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonColor: "#34c38f",
        confirmButtonText: "確認刪除!",
        cancelButtonText: "取消",
      }).then((result) => {
        if (result.value) {
          this.del_user(itemData);
        }
      });
    },
    deleteMultiple() {
      let ids_array = [];
      var items = document.getElementsByName("chk_child");
      items.forEach(function (ele) {
        if (ele.checked == true) {
          var trNode = ele.parentNode.parentNode.parentNode;
          var id = trNode.querySelector(".id a").innerHTML;
          ids_array.push(id);
        }
      });
      if (typeof ids_array !== "undefined" && ids_array.length > 0) {
        if (confirm("Are you sure you want to delete this?")) {
          var cusList = this.data;
          ids_array.forEach(function (id) {
            cusList = cusList.filter(function (orders) {
              return orders.id != id;
            });
          });
          this.data = cusList;
          document.getElementById("checkAll").checked = false;
          var itemss = document.getElementsByName("chk_child");
          itemss.forEach(function (ele) {
            if (ele.checked == true) {
              ele.checked = false;
              ele.closest("tr").classList.remove("table-active");
            }
          });
        } else {
          return false;
        }
      } else {
        Swal.fire({
          title: "Please select at least one checkbox",
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    addModal() {
      document.getElementById("addform").reset();
      document.getElementById("editModalLabel").innerHTML = "新增帳號";
      document.getElementsByClassName("passwordLabel")[0].innerHTML = "密碼";
      document.getElementById("add-btn").style.display = "block";
      document.getElementById("edit-btn").style.display = "none";
      document.getElementById("old_password_block").style.display = "none";
    },
    showdetail(itemData) {
      // document.getElementById("imageid").setAttribute("src", itemData.image_src);
      this.temp_data.account = itemData.account;
      this.temp_data.email = itemData.email;
      this.temp_data.auths = itemData.auths;
      this.temp_data.updated_at = itemData.updated_at;
      this.temp_data.created_at = itemData.created_at;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.data.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
    closeModal() {
      document.getElementById("close-modal").click();
    },

    // api
    async get_users() {
      this.loading = true;
      console.log(this.loading);
      await axios
        .get(process.env.VUE_APP_BACKEND_URL + "user")
        .then((result) => {
          if (result.data.status == 0) {
            return (this.result_msg = result.data.msg);
          }
          this.data = result.data.data;
        })
        .catch((error) => {
          this.result_msg = error.response.data.msg;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async create_user() {
      this.submitted = true;
      this.loading = true;
      await axios
        .post(process.env.VUE_APP_BACKEND_URL + "user", {
          account: this.temp_data.account,
          auths: this.temp_data.auth_selected,
          email: this.temp_data.email,
          password: this.temp_data.password,
          password_confirm: this.temp_data.password_confirm,
        })
        .then((result) => {
          if (result.data.status == 0) {
            this.closeModal();
            return (this.result_msg = result.data.msg);
          }
          this.data.push(result.data.data);
          this.temp_data = {};
          this.result_msg = result.data.msg;
          this.result_msg_variant = "success";
          this.submitted = false;
          this.closeModal();
        })
        .catch((error) => {
          this.result_msg_variant = "danger";
          this.result_msg = error.response.data.msg;
          this.form_errors = error.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async del_user(itemData) {
      this.loading = true;
      await axios
        .delete(`${process.env.VUE_APP_BACKEND_URL}user/${itemData.id}`)
        .then((result) => {
          if (result.data.status == 1) {
            Swal.fire(
              "成功刪除!",
              `${result.data.data.account}已刪除`,
              "success"
            );
            this.data.splice(this.data.indexOf(itemData), 1);
            return;
          }
          Swal.fire("刪除失敗!", `${result.data.msg}`, "error");
        })
        .catch((er) => {
          console.log(er);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async get_auths() {
      this.loading = true;
      await axios
        .get(process.env.VUE_APP_BACKEND_URL + "auth")
        .then((result) => {
          if (result.data.status == 0) {
            return (this.result_msg = result.data.msg);
          }

          let temp_auth_options = [
            {
              label: "頁面管理",
              options: [],
            },
            {
              label: "權限管理",
              options: [],
            },
            {
              //公司資料設定
              label: "",
              options: [],
            },
          ];
          result.data.data.forEach(function (a_id) {
            switch (a_id) {
              case appConfig.auth.pages_group.home:
                temp_auth_options[0].options.push({
                  label: "首頁管理",
                  value: a_id,
                });
                break;
              case appConfig.auth.pages_group.about:
                temp_auth_options[0].options.push({
                  label: "關於我們",
                  value: a_id,
                });
                break;
              case appConfig.auth.pages_group.contact:
                temp_auth_options[0].options.push({
                  label: "聯絡我們",
                  value: a_id,
                });
                break;
              case appConfig.auth.pages_group.news:
                temp_auth_options[0].options.push({
                  label: "最新消息",
                  value: a_id,
                });
                break;
              case appConfig.auth.pages_group.faq:
                temp_auth_options[0].options.push({
                  label: "常見問題",
                  value: a_id,
                });
                break;
              case appConfig.auth.pages_group.award:
                temp_auth_options[0].options.push({
                  label: "實績案例",
                  value: a_id,
                });
                break;
              case appConfig.auth.pages_group.product:
                temp_auth_options[0].options.push({
                  label: "產品服務",
                  value: a_id,
                });
                break;
              case appConfig.auth.pages_group.article:
                temp_auth_options[0].options.push({
                  label: "部落格文章",
                  value: a_id,
                });
                break;
              case appConfig.auth.auth_group.account:
                temp_auth_options[1].options.push({
                  label: "帳號管理",
                  value: a_id,
                });
                break;
              case appConfig.auth.auth_group.ip:
                temp_auth_options[1].options.push({
                  label: "IP管理",
                  value: a_id,
                });
                break;
              case appConfig.auth.company:
                temp_auth_options[2].options.push({
                  label: "公司資料設定",
                  value: a_id,
                });
                break;
              default:
            }
          });
          this.auth_options = temp_auth_options;
          this.temp_data.auth_selected = result.data.data; //預設全選
        })
        .catch((error) => {
          console.log(error);
          // this.result_msg = error.response.data.msg;
        });
    },
  },
  beforeMount() {
    this.get_auths();
    this.get_users();
  },

  mounted() {
    var checkAll = document.getElementById("checkAll");
    if (checkAll) {
      checkAll.onclick = function () {
        var checkboxes = document.querySelectorAll(
          '.form-check-all input[type="checkbox"]'
        );
        if (checkAll.checked == true) {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = true;
            checkbox.closest("tr").classList.add("table-active");
          });
        } else {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = false;
            checkbox.closest("tr").classList.remove("table-active");
          });
        }
      };
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-alert v-model="result_msg" :variant="result_msg_variant" dismissible>
      {{ result_msg }}
    </b-alert>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex align-items-center flex-wrap gap-2">
              <div class="flex-grow-1">
                <button
                  class="btn btn-primary add-btn me-1"
                  data-bs-toggle="modal"
                  href="#editModal"
                  @click="addModal"
                >
                  <i class="ri-add-fill me-1 align-bottom"></i> 新增
                </button>
              </div>
              <div class="flex-shrink-0">
                <div class="hstack text-nowrap gap-2">
                  <!-- <button class="btn btn-soft-danger" @click="deleteMultiple">
                    <i class="ri-delete-bin-2-line"></i>
                  </button> -->
                  <!-- <button class="btn btn-danger">
                    <i class="ri-filter-2-line me-1 align-bottom"></i> 篩選
                  </button>
                  <button class="btn btn-soft-success">匯入</button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-xxl-12">
        <div class="card" id="companyList">
          <div class="card-header">
            <div class="row g-2">
              <div class="col-md-3">
                <div class="search-box">
                  <input
                    type="text"
                    class="form-control search"
                    placeholder="搜尋..."
                    v-model="searchQuery"
                  />
                  <i class="ri-search-line search-icon"></i>
                </div>
              </div>
              <div class="col-md-auto ms-auto">
                <div class="d-flex align-items-center gap-2">
                  <span class="text-muted flex-shrink-0">排序: </span>

                  <Multiselect
                    class="form-control"
                    style="width: 150px"
                    v-model="sortby"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="true"
                    :options="[
                      { value: 'account', label: '帳號' },
                      { value: 'email', label: '信箱' },
                      { value: 'updated_at', label: '更新日期' },
                    ]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <div class="table-responsive table-card mb-3">
                <table
                  class="table align-middle table-nowrap mb-0"
                  id="customerTable"
                >
                  <thead class="table-light">
                    <tr>
                      <th class="sort" data-sort="account" scope="col">帳號</th>
                      <th class="sort" data-sort="email" scope="col">信箱</th>
                      <th class="sort" data-sort="updated_at" scope="col">
                        更新日期
                      </th>
                      <th scope="col">操作</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all" v-if="!loading">
                    <tr v-for="(itemData, index) of resultQuery" :key="index">
                      <!-- 多選框框 -->
                      <!-- <th scope="row">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="chk_child"
                                value="option1"
                              />
                            </div>
                          </th> -->
                      <td class="id" style="display: none">
                        <a
                          href="javascript:void(0);"
                          class="fw-medium link-primary"
                          >{{ itemData.id }}</a
                        >
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-2 name">
                            {{ itemData.account }}
                          </div>
                        </div>
                      </td>
                      <td class="owner">{{ itemData.email }}</td>
                      <td class="industry_type">{{ itemData.updated_at }}</td>
                      <td>
                        <ul class="list-inline hstack gap-2 mb-0">
                          <li
                            class="list-inline-item"
                            data-bs-toggle="modal"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="View"
                            href="#showModal"
                            @click="showdetail(itemData)"
                          >
                            <a href="javascript:void(0);"
                              ><i
                                class="ri-eye-fill align-bottom text-muted"
                              ></i
                            ></a>
                          </li>
                          <li
                            class="list-inline-item"
                            data-bs-toggle="modal"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Edit"
                            href="#editModal"
                            @click="editModal(itemData)"
                          >
                            <a class="edit-item-btn"
                              ><i
                                class="ri-pencil-fill align-bottom text-muted"
                              ></i
                            ></a>
                          </li>
                          <li
                            class="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Delete"
                            @click="deletedata(itemData)"
                          >
                            <a class="remove-item-btn">
                              <i
                                class="ri-delete-bin-fill align-bottom text-muted"
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-center" v-if="loading">
                  <div class="spinner-border text-primary m-5" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>

                <div id="noresult" style="display: none" class="mt-4">
                  <div class="text-center">
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">抱歉! 查無資料</h5>
                    <p class="text-muted mb-0">如有問題，請聯繫客服。</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-end mt-3"
              v-if="pages.length > 1"
            >
              <div class="pagination-wrap hstack gap-2">
                <a
                  class="page-item pagination-prev disabled"
                  href="#"
                  v-if="page != 1"
                  @click="page--"
                >
                  上一頁
                </a>
                <ul class="pagination listjs-pagination mb-0">
                  <li
                    :class="{
                      active: pageNumber == page,
                      disabled: pageNumber == '...',
                    }"
                    v-for="(pageNumber, index) in pages"
                    :key="index"
                    @click="page = pageNumber"
                  >
                    <a class="page" href="#">{{ pageNumber }}</a>
                  </li>
                </ul>
                <a
                  class="page-item pagination-next"
                  href="#"
                  @click="page++"
                  v-if="page < pages.length"
                >
                  下一頁
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <!-- crate, update 共用 modal -->
    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      aria-labelledby="editModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0">
          <div class="modal-header bg-soft-primary p-3">
            <h5 class="modal-title" id="editModalLabel"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            ></button>
          </div>
          <form action="" id="addform">
            <div class="modal-body">
              <input type="hidden" id="id" />
              <div class="row g-3">
                <div class="col-xxl-6">
                  <input type="hidden" id="id" />
                  <div>
                    <label for="account" class="form-label">帳號</label>
                    <input
                      type="text"
                      class="form-control"
                      id="account"
                      name="account"
                      placeholder="請輸入帳號"
                      v-model="temp_data.account"
                      :class="{
                        'is-invalid': submitted && form_errors.account,
                      }"
                    />
                    <div class="invalid-feedback">
                      <span v-if="form_errors.account">{{
                        form_errors.account[0]
                      }}</span>
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-6">
                  <div>
                    <label for="email" class="form-label">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      name="email"
                      placeholder="請輸入信箱"
                      v-model="temp_data.email"
                      :class="{
                        'is-invalid': submitted && form_errors.email,
                      }"
                    />
                    <div class="invalid-feedback">
                      <span v-if="form_errors.email">{{
                        form_errors.email[0]
                      }}</span>
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-xxl-6">
                  <div>
                    <label for="auth" class="form-label">權限</label>
                    <Multiselect
                      v-model="temp_data.auth_selected"
                      mode="tags"
                      :close-on-select="false"
                      :searchable="true"
                      :groups="true"
                      :options="auth_options"
                      :class="{
                        'is-invalid': submitted && form_errors.auths,
                      }"
                    />
                    <div class="invalid-feedback">
                      <span v-if="form_errors.auths">{{
                        form_errors.auths[0]
                      }}</span>
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-xxl-6" id="old_password_block">
                  <div>
                    <label for="old_password" class="form-label">舊密碼</label>
                    <input
                      type="password"
                      class="form-control"
                      id="old_password"
                      name="old_password"
                      placeholder="請輸入舊密碼"
                      v-model="temp_data.old_password"
                      :class="{
                        'is-invalid': submitted && form_errors.old_password,
                      }"
                    />
                    <div class="invalid-feedback">
                      <span v-if="form_errors.old_password">{{
                        form_errors.old_password[0]
                      }}</span>
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-xxl-6">
                  <div>
                    <label for="password" class="form-label passwordLabel"
                      >密碼</label
                    >
                    <input
                      type="password"
                      class="form-control"
                      id="password"
                      name="password"
                      placeholder="請輸入密碼"
                      v-model="temp_data.password"
                      :class="{
                        'is-invalid': submitted && form_errors.password,
                      }"
                    />
                    <div class="invalid-feedback">
                      <span v-if="form_errors.password">{{
                        form_errors.password[0]
                      }}</span>
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-6">
                  <div>
                    <label for="password_confirm" class="form-label"
                      >確認密碼</label
                    >
                    <input
                      type="password"
                      class="form-control"
                      id="password_confirm"
                      name="password_confirm"
                      v-model="temp_data.password_confirm"
                      placeholder="再次輸入密碼"
                      :class="{
                        'is-invalid': submitted && form_errors.password_confirm,
                      }"
                    />
                    <div class="invalid-feedback">
                      <span v-if="form_errors.password_confirm">{{
                        form_errors.password_confirm[0]
                      }}</span>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
            </div>
            <div class="modal-footer">
              <div class="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  class="btn btn-light"
                  id="closemodal"
                  data-bs-dismiss="modal"
                >
                  取消
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  id="add-btn"
                  @click.prevent="create_user"
                >
                  新增
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  id="edit-btn"
                  @click.prevent="updateData"
                >
                  修改
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- show modal -->
    <div
      class="modal fade"
      id="showModal"
      tabindex="-1"
      aria-labelledby="showModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0">
          <div class="modal-header bg-soft-primary p-3">
            <h5 class="modal-title" id="showModalLabel">詳細資料</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            ></button>
          </div>
          <div class="modal-body">
            <input type="hidden" id="id" />
            <div class="row g-3">
              <div class="col-xxl-12">
                <div>
                  <label for="account" class="form-label">帳號：</label>
                  {{ temp_data.account }}
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-12">
                <div>
                  <label for="account" class="form-label">信箱：</label>
                  {{ temp_data.email }}
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-12">
                <div>
                  <label for="account" class="form-label">權限：</label>
                  <span v-for="item in temp_data.auths" :key="item.id">
                    <span v-if="item.id < 100">{{ item.name }} /</span>
                  </span>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-12">
                <div>
                  <label for="account" class="form-label">更新時間：</label>
                  {{ temp_data.updated_at }}
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-12">
                <div>
                  <label for="account" class="form-label">創建時間：</label>
                  {{ temp_data.created_at }}
                </div>
              </div>
              <!--end col-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
